<template>
  <div class="TimeEntryEditor">
    <el-form>
      <div>
        <div
          :span="24"
          class="time-pickers"
        >
          <div>
            <time-picker
              :initial-value="[localTimeEntry.startTime, localTimeEntry.endTime]"
              :disabled="{
                secondTime: timeEntry && timeEntry.active,
              }"
              @input="selectTime"
            />
            <!-- errors block -->
            <span v-if="$v.localTimeEntry.startTime.$error">
              <span
                v-if="!$v.localTimeEntry.startTime.required"
                class="error-message"
              >
                Field is required
              </span>
              <span
                v-if="!$v.localTimeEntry.startTime.validTime"
                class="error-message"
              >
                Invalid time
              </span>
            </span>
          </div>

          <div
            :span="8"
            class="spend-time"
          >
            <span>
              {{ spendTime }}
            </span>
          </div>
        </div>
      </div>
      <el-dialog
        :visible.sync="confirmDeleting"
        title="Delete time entry"
        width="30%"
      >
        <span>
          It will not be undone. Continue?
        </span>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="confirmDeleting = false">
            No
          </el-button>
          <el-button
            type="primary"
            @click="deleteTimeEntry"
          >
            Yes
          </el-button>
        </span>
      </el-dialog>
      <div class="actions">
        <div class="action-buttons">
          <el-button
            :disabled="formInvalid"
            :loading="localTimeEntry.loading"
            type="success"
            size="middle"
            title="Save"
            @click.prevent="saveTimeEntry"
          >
            Save
          </el-button>
          <el-button
            type="plain"
            size="middle"
            @click.prevent="closeEditor"
          >
            Cancel
          </el-button>
          <el-button
            v-if="localTimeEntry.exists()"
            type="text"
            class="delete_button"
            @click.prevent="confirmDeleting = true"
          >
            Delete Time Entry
          </el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import { TimeEntry } from '@timeragent/core';
import { showError } from '../../../notification';

export default {
  components: {
    TimePicker: () => import('@/components/blocks/TimePicker.vue'),
  },

  props: {
    timeEntry: {
      type: Object,
      required: false,
      default: () => null,
    },
    task: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      localTimeEntry: this.timeEntry ? this.timeEntry.clone() : new TimeEntry(),
      projects: [],
      prevDuration: 0,
      confirmDeleting: false,
    };
  },
  computed: {
    ...mapGetters([
      'date',
      'activeTimeEntry',
    ]),
    spendTime() {
      if (this.localTimeEntry.startTime !== '' && this.localTimeEntry.endTime !== '') {
        const spendTime = moment.duration(moment(this.localTimeEntry.endTime, 'HH:mm:ss')
          .diff(moment(this.localTimeEntry.startTime, 'HH:mm:ss')));

        if (spendTime.seconds() >= 0) {
          return `${spendTime.hours()}  h  ${spendTime.minutes()}  min `;
        }
      }

      return ' ';
    },
    formInvalid() {
      return this.$v.$invalid;
    },
  },

  created() {
    this.prevDuration = this.localTimeEntry.getDuration();
  },

  methods: {
    selectTime({ firstTime, secondTime }) {
      const selectedDate = moment(this.date);
      const date = {
        date: selectedDate.date(),
        month: selectedDate.month(),
        year: selectedDate.year(),
      };

      this.localTimeEntry.startTime = moment(firstTime).set(date).toDate();
      this.localTimeEntry.endTime = moment(secondTime).set(date).toDate();
    },

    closeEditor() {
      this.$emit('close-editor');
    },

    async saveTimeEntry() {
      if (this.$v.$invalid) return;

      if (this.localTimeEntry.exists()) {
        await this.localTimeEntry.update();

        this.$emit('update', {
          timeEntry: this.localTimeEntry,
          prevDuration: this.prevDuration,
          curDuration: this.localTimeEntry.getDuration(),
        });

        return;
      }

      this.localTimeEntry.task = this.task.clone();

      try {
        const createdTimeEntry = await this.localTimeEntry.create();

        this.$emit('create', createdTimeEntry);
        this.closeEditor();
      } catch (e) {
        console.error(e);
        showError(e.message);
      }
    },

    async deleteTimeEntry() {
      try {
        await this.localTimeEntry.delete();

        this.$emit('delete', this.localTimeEntry);
        this.closeEditor();
      } catch (e) {
        console.error(e);
        showError(e.message);
      }
    },
  },

  validations() {
    const timeEntryUuid = (this.timeEntry) ? this.timeEntry.uuid : 0;

    if (this.$store.getters.activeTimeEntry === timeEntryUuid) {
      return {
        localTimeEntry: {
          startTime: {
            required,
            validTime(value) {
              return moment(value, 'HH:mm:ss').isBefore(moment()) && value !== '';
            },
          },
          endTime: {},
        },
      };
    }

    return {
      localTimeEntry: {
        startTime: {
          required,
          validTime(value) {
            let valid = false;
            const now = moment();

            if (moment(this.date).isBefore(moment().startOf('day'))) {
              valid = true;
            } else {
              valid = moment(value).isBefore(now);
            }

            return valid && value !== '';
          },
        },
        endTime: {
          required,
          validTime(value) {
            let isAfter = true;
            let valid = false;
            const now = moment();

            if (this.localTimeEntry.startTime !== '') {
              isAfter = moment(this.localTimeEntry.endTime)
                .isAfter(moment(this.localTimeEntry.startTime));
            }
            if (moment(this.date).isBefore(moment().startOf('day'))) {
              valid = true;
            } else {
              valid = moment(value).isBefore(now);
            }

            return valid && value !== '' && isAfter;
          },
        },
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus"
       rel="stylesheet/css"
       scoped
>

  .TimeEntryEditor {
    margin-top: 20px;
    margin-bottom: 7px;
  }

  .actions {
    display: flex;
    align-items: center;
    text-align: left;
    margin-top: 20px;
  }

  .actions > :not(:last-child) {
    margin-right: 12px;
  }

  .time-pickers {
    display: flex;
    justify-content: flex-start;
  }

  .spend-time {
    text-align: center;
    line-height: 40px;
    color: #b4bccc;
    margin-left: 20px;
  }

</style>
